"use client";

import Container from "@/components/ui/container";
import Info from "@/components/layouts/info";
import Link from "next/link";
import { VERSION } from "@/utils/config";
export function Footer() {
  return <>
      <Container className="bg-white" data-sentry-element="Container" data-sentry-source-file="footer.tsx" data-sentry-element="Container">
        <div className="max-w-screen-lg mx-auto ">
          <Info data-sentry-element="Info" data-sentry-source-file="footer.tsx" data-sentry-element="Info" />
        </div>
      </Container>
      <footer className="text-center p-2 bg-gray-800 text-primary-600 flex flex-col text-sm">
        <Link href="https://github.com/paroki/pkrbt/releases" data-sentry-element="Link" data-sentry-source-file="footer.tsx" data-sentry-element="Link">
          PKRBT website versi <strong>{VERSION}</strong>
        </Link>
        <Link href="https://github.com/paroki/pkrbt" data-sentry-element="Link" data-sentry-source-file="footer.tsx" data-sentry-element="Link">
          &copy; 2024 PKRBT Developers
        </Link>
      </footer>
    </>;
}