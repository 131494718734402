"use client";

import Link from "next/link";
import { FacebookCircle, Instagram, Youtube } from "@/components/icons/socials";
export default function SocialMedia() {
  return <ul className="flex justify-center gap-5" data-sentry-component="SocialMedia" data-sentry-source-file="social-media.tsx">
      <li>
        <Link href="https://www.instagram.com/gkrbt1937" className="p-2 text-primary-50 rounded-full bg-primary-400 block" data-sentry-element="Link" data-sentry-source-file="social-media.tsx" data-sentry-element="Link">
          <Instagram data-sentry-element="Instagram" data-sentry-source-file="social-media.tsx" data-sentry-element="Instagram" />
        </Link>
      </li>
      <li>
        <Link href="https://www.facebook.com/kristus.raja" className="p-2 text-primary-50 rounded-full bg-primary-400 block" data-sentry-element="Link" data-sentry-source-file="social-media.tsx" data-sentry-element="Link">
          <FacebookCircle data-sentry-element="FacebookCircle" data-sentry-source-file="social-media.tsx" data-sentry-element="FacebookCircle" />
        </Link>
      </li>
      <li>
        <Link href="https://www.youtube.com/@gkrbt" className="p-2 text-primary-50 rounded-full bg-primary-400 block" data-sentry-element="Link" data-sentry-source-file="social-media.tsx" data-sentry-element="Link">
          <Youtube data-sentry-element="Youtube" data-sentry-source-file="social-media.tsx" data-sentry-element="Youtube" />
        </Link>
      </li>
    </ul>;
}