"use client";

import Address from "@/components/layouts/address";
import ContainerHeader from "@/components/layouts/container-header";
import ServiceTime from "@/components/layouts/service-time";
import SocialMedia from "@/components/layouts/social-media";
export default function Info() {
  return <div className="grid gap-7 py-6 sm:grid-cols-2 md:grid-cols-3 text-sm leading-9" data-sentry-component="Info" data-sentry-source-file="info.tsx">
      <div>
        <ContainerHeader size="smaller" className="text-center mb-4 border-b pb-2" data-sentry-element="ContainerHeader" data-sentry-source-file="info.tsx" data-sentry-element="ContainerHeader">
          Media Paroki
        </ContainerHeader>
        <SocialMedia data-sentry-element="SocialMedia" data-sentry-source-file="info.tsx" data-sentry-element="SocialMedia" />
      </div>
      <div>
        <ContainerHeader size="smaller" className="text-center mb-4 border-b pb-2" data-sentry-element="ContainerHeader" data-sentry-source-file="info.tsx" data-sentry-element="ContainerHeader">
          Jadwal Pelayanan
        </ContainerHeader>
        <ServiceTime data-sentry-element="ServiceTime" data-sentry-source-file="info.tsx" data-sentry-element="ServiceTime" />
      </div>
      <div>
        <ContainerHeader size="smaller" className="text-center mb-4 border-b pb-2" data-sentry-element="ContainerHeader" data-sentry-source-file="info.tsx" data-sentry-element="ContainerHeader">
          Alamat
        </ContainerHeader>
        <Address data-sentry-element="Address" data-sentry-source-file="info.tsx" data-sentry-element="Address" />
      </div>
    </div>;
}