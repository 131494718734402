import(/* webpackMode: "eager" */ "/home/runner/work/pkrbt/pkrbt/apps/web/src/assets/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/runner/work/pkrbt/pkrbt/apps/web/src/components/layouts/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/home/runner/work/pkrbt/pkrbt/apps/web/src/components/layouts/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/pkrbt/pkrbt/apps/web/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/pkrbt/pkrbt/node_modules/.pnpm/@next+third-parties@14.2.17_next@14.2.17_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwri_vz7rqf3dvpzemhvnhygnmpdpgm/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/pkrbt/pkrbt/node_modules/.pnpm/@next+third-parties@14.2.17_next@14.2.17_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwri_vz7rqf3dvpzemhvnhygnmpdpgm/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/pkrbt/pkrbt/node_modules/.pnpm/@next+third-parties@14.2.17_next@14.2.17_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwri_vz7rqf3dvpzemhvnhygnmpdpgm/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/pkrbt/pkrbt/node_modules/.pnpm/next@14.2.17_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.47.2_react-dom@18_5qtrny6kg762trb4zpe424oat4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/pkrbt/pkrbt/node_modules/.pnpm/next@14.2.17_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.47.2_react-dom@18_5qtrny6kg762trb4zpe424oat4/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
