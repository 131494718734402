'use client';

const Day = ({
  day,
  time
}: {
  day: string;
  time: string;
}) => {
  return <div className="flex" data-sentry-component="Day" data-sentry-source-file="service-time.tsx">
      <div className="flex-[2] font-bold">{day}</div>
      <div className="flex-[1]">{time}</div>
    </div>;
};
export default function ServiceTime() {
  return <div className="misa-group" data-sentry-component="ServiceTime" data-sentry-source-file="service-time.tsx">
      <Day day="Senin-Sabtu" time="08.00 - 20.00" data-sentry-element="Day" data-sentry-source-file="service-time.tsx" data-sentry-element="Day" />
      <Day day="Minggu" time="08.00 - 15.00" data-sentry-element="Day" data-sentry-source-file="service-time.tsx" data-sentry-element="Day" />
    </div>;
}